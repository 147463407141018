<template>
  <div v-if="repairsList">
    <div class="back">
      <button
        class="btn btn-sm btn-outline-secondary shadow-none mb-1"
        @click="back"
        disabled
      >
        <i class="bi bi-arrow-left"></i> назад
      </button>
      <button
        class="btn btn-sm btn-outline-primary shadow-none mb-1"
        @click="createReport"
        :disabled="sendReportDisabled"
        v-if="
          (disableButtonSendReport && !dataReport[0].date_report_check) ||
            (dataReport[0].no_act_and_invoice === '52' &&
              !dataReport[0].date_report_check)
        "
      >
        отправить отчет на проверку
      </button>
      <button
        class="btn btn-sm btn-outline-success shadow-none mb-1"
        @click="confirmReport"
        v-if="
          dataReport[0].date_report_check &&
            USER_INFO.status === 'admin' &&
            !dataReport[0].date_report_confirm
        "
      >
        подтвердить
      </button>
      <button
        class="btn btn-sm btn-outline-warning shadow-none mb-1"
        @click="cancelCheckReport"
        v-if="
          dataReport[0].date_report_check &&
            USER_INFO.status === 'admin' &&
            !dataReport[0].date_report_confirm
        "
      >
        отменить проверку
      </button>
      <button
        class="btn btn-sm btn-outline-warning shadow-none mb-1"
        @click="cancelConfirmReport"
        v-if="
          dataReport[0].date_report_check &&
            USER_INFO.status === 'admin' &&
            dataReport[0].date_report_confirm
        "
      >
        отменить подтверждение
      </button>
    </div>
    <div class="table-wrapper">
      <div class="row col-md-12">
        <div
          class="col-md-6"
          v-if="
            !dataReport[0].date_report_check ||
              (dataReport[0].date_report_check && USER_INFO.status === 'admin')
          "
        >
          <DownloadDocReport
            :dateReport="
              dataReport[0].date_start + '_' + dataReport[0].date_end
            "
            @update="update"
          />
        </div>
        <div class="col-md-6"><ReportChat /></div>
      </div>
      <div>
        <ReportDocsViews
          :dateReport="dataReport[0].date_start + '_' + dataReport[0].date_end"
          @countDocs="countDocs"
          :key="'dok' + docKey"
          :date_report_check="dataReport[0].date_report_check"
          :date_report_confirm="dataReport[0].date_report_confirm"
        />
      </div>
      <div v-if="USER_INFO.status === 'admin' && repairsList">
        <add-in-report-additional-services
          @update="get_data"
          v-if="!dataReport[0].date_report_confirm"
        />
        <ReportStats :data="repairsList" :data_report="dataReport[0]" />
      </div>
      <div v-if="additional_services">
        <div
          class="alert alert-primary col-md-4 p-1 ml-2 mt-1"
          role="alert"
          v-for="(item, idx) in additional_services"
          :key="idx + 'serv'"
        >
          {{ `${item.name} - ${item.price} р.` }}
          <i
            class="bi bi-trash float-right cursor"
            v-if="
              USER_INFO.status === 'admin' && !dataReport[0].date_report_confirm
            "
            @click="delete_additional_services(item.id)"
          ></i>
        </div>
      </div>
      <div class="text-center table-header" v-if="dataReport">
        отчет
        {{ USER_INFO.status === "admin" ? dataReport[0].user : null }}
        с {{ dataReport[0].date_report_start }} по
        {{ dataReport[0].date_report_end }}
      </div>
      <div class="overflow">
        <table class="table table-sm">
          <tr>
            <th></th>
            <th>№ квит</th>
            <th>№</th>
            <th>наименование инструмента</th>
            <th>дата продажи</th>
            <th>дата принятия в ремонт</th>
            <th>дефект</th>
            <th>проведенные работы</th>
            <th class="min">запчасти</th>
            <th>№ накладной</th>
            <th>дата накладной</th>
            <th>цена детали</th>
            <th>шт</th>
            <th v-html="'кате<br>гория'"></th>
            <th>стоимость работ</th>
          </tr>
          <template v-for="(item, idx) in repairsList">
            <tr
              @click="goRepair(item.remont_id)"
              :key="idx + 'jjj'"
              class="cursor"
            >
              <td>{{ idx + 1 }}</td>
              <td>{{ item.receiptNumber }}</td>
              <td>{{ item.repair_number }}</td>
              <td>
                {{ item.name_instr }}
                <div v-if="item.gruppa_sc" class="name_user">
                  {{ item.user }}
                </div>
                <div class="font-weight-bold text-center text-danger">
                  {{ item.garantiya ? "не гарантия" : null }}
                  {{ item.no_defect ? "нет дефекта" : null }}
                </div>
              </td>
              <td class="text-center">{{ item.dateSale }}</td>
              <td class="text-center">{{ item.dateRepair }}</td>
              <td>{{ item.defect }}</td>
              <td>{{ item.workCarried }}</td>
              <td
                v-if="item.zap.length > 0"
                class="min"
                :title="item.zap[0].name"
              >
                {{ item.zap[0].name.substr(0, 55) }}
              </td>
              <td v-else>{{ type(item.typeOfRepair) }}</td>
              <td v-if="item.zap.length > 0" class="text-center">
                <span v-for="(item3, idx3) in item.zap" :key="'kdg' + idx3">{{
                  idx3 === 0 ? item3.nomer_nakl : null
                }}</span>
              </td>
              <td v-else></td>
              <td v-if="item.zap.length > 0" class="text-center">
                <span v-for="(item3, idx3) in item.zap" :key="'kdh' + idx3">{{
                  idx3 === 0 ? item3.data_nakl : null
                }}</span>
              </td>
              <td v-else></td>
              <td v-if="item.zap.length > 0" class="text-center">
                <span v-for="(item3, idx3) in item.zap" :key="'kdj' + idx3"
                  >{{ idx3 === 0 ? item3.cena : null
                  }}{{ idx3 === 0 ? item3.price : null }}
                  <div class="text-danger font-weight-bold">
                    {{
                      idx3 === 0 && item3.zap_free === "1" ? "бесплатно" : null
                    }}
                  </div>
                </span>
              </td>
              <td v-else></td>
              <td v-if="item.zap.length > 0" class="text-center">
                <span v-for="(item3, idx3) in item.zap" :key="'kdjg' + idx3">{{
                  idx3 === 0 ? item3.pcs : null
                }}</span>
              </td>
              <td v-else></td>
              <td class="text-center">{{ item.repair_category }}</td>
              <td class="text-center">{{ item.repair_price }}</td>
            </tr>
            <tr
              v-for="(item2, h) in item.zap"
              :key="item2.id_nakl_z + h + 'g' + item2.key + h"
              :class="`hide${h}`"
            >
              <td colspan="8" class="no-border"></td>
              <td :title="item2.name">
                {{ h > 0 ? item2.name.substr(0, 55) : null }}
              </td>
              <td class="text-center">
                {{ h > 0 ? item2.nomer_nakl : null }}
              </td>
              <td class="text-center">{{ h > 0 ? item2.data_nakl : null }}</td>
              <td class="text-center">
                {{ h > 0 ? item2.cena : null }}
                {{ h > 0 ? item2.price : null }}
                <div class="text-danger font-weight-bold">
                  {{ item2.zap_free === "1" ? "бесплатно" : null }}
                </div>
              </td>
              <td class="text-center">{{ h > 0 ? item2.pcs : null }}</td>
              <td colspan="2" class="no-border"></td>
            </tr>
          </template>
          <template v-if="additional_services">
            <tr v-for="(item, idx) in additional_services" :key="idx + 'serv2'">
              <td colspan="8"></td>
              <td>{{ item.name }}</td>
              <td colspan="5"></td>
              <td class="text-center">{{ item.price }}</td>
            </tr>
          </template>
          <tr>
            <td colspan="11"></td>
            <td class="text-center">{{ dataReport[0].summ_repair_zap }}</td>
            <td class="text-center">{{ dataReport[0].summ_pcs }}</td>
            <td></td>
            <td class="text-center">{{ dataReport[0].summ_repair_price }}</td>
          </tr>
          <tr>
            <td colspan="11"></td>
            <td class="text-center" colspan="4">
              {{
                `итого: ${dataReport[0].summ_repair_zap +
                  dataReport[0].summ_repair_price}`
              }}
            </td>
          </tr>
        </table>
      </div>
    </div>
    <ModalMessage
      v-if="messageVisible"
      :message="message"
      @hideMessage="hideMessage"
    />
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { HTTPGET } from "@/api";
import DownloadDocReport from "./DownloadDocReport.vue";
import ReportDocsViews from "./ReportDocsViews.vue";
import ReportChat from "./ReportChat.vue";
import ModalMessage from "@/components/ModalMessage.vue";
import ReportStats from "./ReportStats.vue";
import AddInReportAdditionalServices from "./AddInReportAdditionalServices.vue";
export default {
  name: "OneReport",
  components: {
    DownloadDocReport,
    ReportChat,
    ReportDocsViews,
    ModalMessage,
    ReportStats,
    AddInReportAdditionalServices
  },
  computed: {
    ...mapGetters(["USER_INFO", "GET_PATH_FROM", "PATH"])
  },
  data: () => ({
    repairsList: null,
    dataReport: null,
    docKey: 0,
    disableButtonSendReport: false,
    messageVisible: false,
    message: "",
    sendReportDisabled: false,
    additional_services: null
  }),
  methods: {
    ...mapActions(["ADDPATH"]),
    back() {
      if (this.GET_PATH_FROM === "/login") {
        this.$router.push("/home");
      } else {
        this.$router.go(-1);
      }
    },
    get_data() {
      HTTPGET({
        url: "/reports/get_one_report.php",
        params: {
          id: this.$route.params.id
        }
      })
        .then(response => {
          this.repairsList = response.data.report;
          this.dataReport = response.data.data_report;
          this.additional_services = response.data.additional_services;
        })
        .then(() =>
          this.ADDPATH(
            `${this.repairsList[0].user} / отчет с ${this.dataReport[0].date_report_start} по ${this.dataReport[0].date_report_end}`
          )
        );
    },
    goRepair(id) {
      this.$router.push(
        `/repairs/repair/${id}?path=отчет&from=order&order_id=${this.$route.params.id}`
      );
    },
    type(type) {
      if (type === "noRepair") {
        return "выдача акта неремонтопригодности";
      }
      if (type === "diagnostiks") {
        return "диагностика или ремонт без зч";
      }
    },
    update() {
      this.docKey += 1;
    },
    countDocs(r) {
      this.disableButtonSendReport = r.length;
    },
    sendReport() {
      HTTPGET({
        url: "/reports/send_check_report.php",
        params: {
          id: this.$route.params.id
        }
      })
        .then(() => {
          this.messageVisible = true;
          this.message = `
          отчет отправлен на проверку |
          о результатах проверки - мы обязательно уведомим Вас |
          по эл. почте указанной в реквизитах |
          как только Вы получите сообщение о том что отчет подтвержден |
          высылайте почтой <span style="font-weight: bold;color: red">заказным письмом</span> следующие документы: |
            отчет - 1шт. |
            акт выполненных работ - 2шт. |
            счет на оплату 1шт. |

            на наш почтовый адрес который будет указан в письме о подтверждении отчета
        `;
        })
        .then(() => this.get_data());
    },
    createReport() {
      this.sendReportDisabled = true;
      HTTPGET({
        url: "/reports/report_in_zip.php",
        params: {
          id: this.$route.params.id,
          report: true,
          only_report: true,
          zip: true
        }
      }).then(() => this.sendReport());
    },
    hideMessage() {
      this.messageVisible = false;
    },
    confirmReport() {
      if (confirm("подтвердить отчет")) {
        HTTPGET({
          url: "/reports/confirm_report.php",
          params: {
            id: this.$route.params.id
          }
        }).then(() => this.get_data());
      }
    },
    cancelConfirmReport() {},
    cancelCheckReport() {
      HTTPGET({
        url: "/reports/cancel_check_report.php",
        params: {
          id: this.$route.params.id
        }
      }).then(() => this.get_data());
    },
    delete_additional_services(id) {
      HTTPGET({
        url: "reports/delete_from_reports_additional_services.php",
        params: {
          id,
          report_id: this.$route.params.id
        }
      }).then(() => this.get_data());
    }
  },
  mounted() {
    this.get_data();
  }
};
</script>
<style lang="scss" scoped>
.back {
  padding: 5px;
}
.table-wrapper {
  padding: 5px;
}
.overflow {
  overflow: auto;
}
table {
  font-size: 0.7em;
}
.hide0 {
  display: none;
}
.min {
  width: 200px;
}
td,
th {
  vertical-align: middle;
}
th {
  text-align: center;
}
.cursor {
  cursor: pointer;
}
.table-header {
  font-size: 0.7em;
  font-weight: bold;
  margin-top: 10px;
}
.btn {
  margin-right: 10px;
}
.name_user {
  font-size: 0.7em;
  font-weight: bold;
}
.no-border {
  border: none;
}
</style>
