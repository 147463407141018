<template>
  <div>
    <div>документы по отчету</div>
    <div class="header">
      <div>нужно загрузить сканы акта выполненных работ и счета</div>
      <div v-if="requizit">
        <div>
          <div>
            если Вы будете использовать наши формы
          </div>
          <div>
            то введите номера и даты счета и акта и нажмите
            <a href="#" @click.prevent="downloadAct">скачать</a>
          </div>
        </div>
        <div class="row col-md-12 form-group">
          <input
            type="text"
            v-model="numberAct"
            class="form-control form-control-sm input-form shadow-none col-md-5"
            placeholder="номер акта выполненных работ"
          />
          <DatePicker
            v-model="dateAct"
            valueType="DD.MM.YYYY"
            :input-attr="actDateAttr"
          ></DatePicker>
        </div>
        <div class="row col-md-12 form-group">
          <input
            type="text"
            v-model="numberInvoice"
            class="form-control form-control-sm input-form shadow-none col-md-5"
            placeholder="номер счета"
          />
          <DatePicker
            v-model="dateInvoice"
            valueType="DD.MM.YYYY"
            :input-attr="invoiceDateAttr"
          ></DatePicker>
        </div>
      </div>
      <div v-else>
        вы можете сгенерировать акт и счет в нашей форме если заполните
        реквизиты <router-link to="/kabinet/users/user/1">тут</router-link>
      </div>
    </div>
    <div>
      <button class="btn btn-sm btn-warning shadow-none" @click="addFile">
        загрузить сканы акта и счета в своей или в нашей форме
      </button>
    </div>
    <div>
      <input
        type="file"
        multiple
        id="file"
        ref="file"
        @change="uploadAct"
        accept="image/jpeg,image/png,image/jpg,application/pdf"
      />
    </div>
    <ModalMessage
      v-if="messageVisible"
      :message="message"
      @hideMessage="hideMessage"
    />
  </div>
</template>
<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/ru";
import { HTTPGET, HTTPPOSTFILE } from "@/api";
import ModalMessage from "@/components/ModalMessage.vue";
export default {
  name: "DownloadDocReport",
  props: ["dateReport"],
  components: {
    DatePicker,
    ModalMessage
  },
  computed: {
    inputLength: function() {
      if (
        this.numberAct.length < 1 ||
        this.dateAct.length < 1 ||
        this.numberInvoice.length < 1 ||
        this.dateInvoice.length < 1
      ) {
        return true;
      } else {
        return false;
      }
    }
  },
  data: () => ({
    messageVisible: false,
    message: "",
    numberAct: "",
    dateAct: "",
    numberInvoice: "",
    dateInvoice: "",
    actDateAttr: {
      class: "form-control form-control-sm input-form shadow-none",
      placeholder: "дата акта"
    },
    invoiceDateAttr: {
      class: "form-control form-control-sm input-form shadow-none",
      placeholder: "дата счета"
    },
    typeFileFoto: /\.(jpe?g|png|gif)$/i,
    act: [],
    invoice: [],
    requizit: true
  }),
  mounted() {
    this.checkRequizits();
  },
  methods: {
    downloadAct() {
      if (this.requizit) {
        if (this.inputLength) {
          alert("заполните все поля");
        } else {
          HTTPGET({
            url: "/reports/report_in_zip.php",
            responseType: "blob",
            params: {
              zip: true,
              act: true,
              invoice: true,
              report: false,
              id: this.$route.params.id,
              date_invoice: this.dateInvoice,
              number_invoice: this.numberInvoice,
              date_act: this.dateAct,
              number_act: this.numberAct
            }
          }).then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "file.zip");
            document.body.appendChild(link);
            link.click();
          });
        }
      }
    },
    addFile() {
      const input = document.getElementById("file");
      input.click();
    },
    uploadAct() {
      let formData = new FormData();
      for (let i = 0; i < this.$refs.file.files.length; i++) {
        formData.append(`file${i}`, this.$refs.file.files[i]);
      }
      let data = {
        id: this.$route.params.id,
        date_report: this.dateReport
      };
      formData.append("data", JSON.stringify(data));
      HTTPPOSTFILE({
        url: "/reports/add_file_for_report.php",
        data: formData
      })
        .then(response => {
          if (response.data.files) {
            this.message = response.data.text + "|";
            this.message += "не загружены следующие файлы: |";
            for (let file in response.data.files) {
              this.message += `${response.data.files[file].name}|`;
            }
            this.messageVisible = true;
          }
        })
        .then(() => this.$emit("update"))
        .then(() => (document.getElementById("file").value = ""));
    },
    hideMessage() {
      this.messageVisible = false;
    },
    checkRequizits() {
      HTTPGET({
        url: "/admin/users/get_one_user.php",
        params: {
          id: this.$route.params.id,
          check: true
        }
      }).then(response => {
        if (
          response.data.a.bik === "" ||
          response.data.a.adres === "" ||
          response.data.a.inn === "" ||
          response.data.a.k_s === "" ||
          response.data.a.naim_bank === "" ||
          response.data.a.r_s === "" ||
          response.data.a.ruk === ""
        ) {
          this.requizit = false;
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.header,
.custom-checkbox {
  font-size: 0.8em;
  font-weight: bold;
}
#file {
  position: absolute;
  top: -10000px;
  left: -10000px;
}
a {
  margin-right: 10px;
}
</style>
