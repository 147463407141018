<template>
  <div class="docs m mm doc-wrapper">
    <div v-if="docsList" class="docs">
      <div
        v-for="(item, idx) in docsList"
        :key="idx"
        class="alert alert-primary"
      >
        <a :href="item.href" target="blank"><i class="bi bi-download"></i></a
        >{{ item.name }}
        <i
          class="bi bi-trash"
          @click="del(item.name)"
          v-if="
            !date_report_check ||
              (date_report_check && USER_INFO.status === 'admin')
          "
        ></i>
      </div>
    </div>
    <div v-if="date_report_confirm" class="alert alert-primary">
      <a :href="downloadUrl" target="blank"><i class="bi bi-download"></i></a>
      скачать отчет в excel
    </div>
  </div>
</template>
<script>
import { HTTPGET } from "@/api";
import { mapGetters } from "vuex";
export default {
  name: "ReportDocsViews",
  computed: {
    ...mapGetters(["USER_INFO"])
  },
  props: ["dateReport", "date_report_check", "date_report_confirm"],
  data: () => ({
    docsList: null,
    downloadUrl: null
  }),
  mounted() {
    this.get_data();
  },
  watch: {
    docsList: function() {
      if (this.docsList !== null) {
        this.$emit("countDocs", {
          length: true
        });
      } else {
        this.$emit("countDocs", {
          length: false
        });
      }
    }
  },
  methods: {
    get_data() {
      HTTPGET({
        url: "/reports/get_file_for_report.php",
        params: {
          id: this.$route.params.id,
          date_report: this.dateReport
        }
      }).then(response => {
        this.docsList = response.data.files;
        this.downloadUrl = response.data.download;
      });
    },
    del(name) {
      if (confirm(`удалить - ${name}?`)) {
        HTTPGET({
          url: "/reports/get_file_for_report.php",
          params: {
            id: this.$route.params.id,
            date_report: this.dateReport,
            name
          }
        }).then(() => this.get_data());
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.doc-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.btn {
  font-size: 0.7em;
  margin-right: 10px;
}
.docs {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
a {
  margin-right: 10px;
}
i {
  cursor: pointer;
}
.m {
  margin-left: 10px;
}
.mm {
  margin-top: 10px;
}

.alert {
  font-size: 0.8em;
  padding: 5px;
  margin-right: 5px;
}
</style>
