<template>
  <div class="wrapper mb-2">
    <div>
      <a href="" @click.prevent="add_services"
        >добавить дополнительные работы</a
      >
    </div>
    <div v-if="add_visible">
      <div class="form-row">
        <input
          id="serialNumber"
          type="text"
          class="form-control form-control-sm input-form shadow-none col-md-4 m-1"
          placeholder="название услуги"
          v-model="name"
        />
      </div>
      <div class="form-row">
        <input
          id="serialNumber"
          type="number"
          class="form-control form-control-sm input-form shadow-none col-md-1 m-1"
          placeholder="стоимость"
          v-model="price"
        />
        <button
          class="btn btn-sm btn-outline-primary m-1 shadow-none"
          @click="save"
          :disabled="disabled"
        >
          добавить
        </button>
        <button
          class="btn btn-sm btn-outline-primary m-1 shadow-none"
          @click="update_report"
        >
          сформировать новый отчет
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { HTTPGET } from "@/api";
export default {
  name: "AddInReportAdditionalServices",
  computed: {
    disabled: function() {
      if (this.name.length > 0 && this.price > 0) {
        return false;
      }
      return true;
    }
  },
  mounted() {
    console.log(this.$route.params.id);
  },
  data: function() {
    return {
      add_visible: false,
      name: "",
      price: 0
    };
  },
  methods: {
    save() {
      HTTPGET({
        url: "reports/add_in_reports_additional_services.php",
        params: {
          id: this.$route.params.id,
          name: this.name,
          price: this.price
        }
      })
        .then(() => this.$emit("update"))
        .then(() => {
          this.name = "";
          this.price = 0;
          this.add_visible = false;
        });
    },
    add_services() {
      this.add_visible = !this.add_visible;
      if (!this.add_visible) {
        this.name = "";
        this.price = 0;
        this.add_visible = false;
      }
    },
    update_report() {
      HTTPGET({
        url: "/reports/report_in_zip.php",
        params: {
          id: this.$route.params.id,
          report: true,
          only_report: true,
          zip: true
        }
      });
    }
  }
};
</script>
<style scoped>
.wrapper {
  margin-left: 10px;
}
</style>
