<template>
  <div>
    <div class="wrapper">
      <span class="badge badge-dark">всего {{ data.length }}шт.</span>
      <div class="badge badge-primary" v-if="predtorg().count">
        предторг {{ predtorg().count }}шт. {{ parseInt(predtorg().percent) }}%
      </div>
      <div class="badge badge-warning" v-if="acts().count">
        акты {{ acts().count }}шт. {{ parseInt(acts().percent) }}%
      </div>
      <div class="badge badge-info" v-if="diagnostiks().count">
        диагностики {{ diagnostiks().count }}шт.
        {{ parseInt(diagnostiks().percent) }}%
      </div>
      <div class="badge badge-success" v-if="repair().count">
        ремонты {{ repair().count }}шт. {{ parseInt(repair().percent) }}%
      </div>
    </div>
    <div class="badge badge-primary wrapper" v-if="data_report">
      <span v-if="data_report.summ_pcs">{{
        `запчасти - ${data_report.summ_pcs}шт. ${data_report.summ_repair_zap}р. `
      }}</span>
      <span v-if="data_report.summ_repair_price">{{
        `работы - ${data_report.summ_repair_price}р. `
      }}</span>
      <span>{{
        `сумма - ${data_report.summ_repair_price +
          data_report.summ_repair_zap}р.`
      }}</span>
    </div>
  </div>
</template>
<script>
export default {
  name: "ReportStats",
  props: ["data", "data_report"],
  methods: {
    predtorg() {
      let count = 0;
      this.data.map(item => {
        if (!item.dateSale) {
          count++;
        }
      });
      let percent = (count / this.data.length) * 100;
      let d = {
        count,
        percent
      };
      return d;
    },
    acts() {
      let count = 0;
      this.data.map(item => {
        if (item.act !== "0") {
          count++;
        }
      });
      let percent = (count / this.data.length) * 100;
      let d = {
        count,
        percent
      };
      return d;
    },
    diagnostiks() {
      let count = 0;
      this.data.map(item => {
        if (item.typeOfRepair === "diagnostiks") {
          count++;
        }
      });
      let percent = (count / this.data.length) * 100;
      let d = {
        count,
        percent
      };
      return d;
    },
    repair() {
      let count = 0;
      this.data.map(item => {
        if (item.typeOfRepair === "repair") {
          count++;
        }
      });
      let percent = (count / this.data.length) * 100;
      let d = {
        count,
        percent
      };
      return d;
    }
  },
  mounted() {}
};
</script>
<style scoped>
.wrapper {
  margin-left: 10px;
}
.badge {
  margin-right: 5px;
}
</style>
