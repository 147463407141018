<template>
  <div>
    <Chat
      title="переписка по отчету"
      urlSendMessage="repairs/chat/chat_of_report.php"
      urlDeleteMessage="repairs/chat/del_message_report.php"
    />
  </div>
</template>
<script>
import Chat from "@/views/repairs/chat/Chat.vue";

export default {
  name: "ReportChat",
  components: {
    Chat
  },
  data: () => ({})
};
</script>
